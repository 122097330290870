.logo {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    animation: move 5s linear infinite;
  }
  @keyframes move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

:global {
  .ant-layout-header {
    height: 84px !important;
    background: none !important;
    line-height: normal !important;
  }

  .site-layout-background::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.header {
  height: 88px;
  padding: 0px 0px !important;
  background: #000;
  .topHeader {
    height: 44px;
    background: #fff;
    padding: 0px 10px;
  }
  .navDrop {
    height: 100%;
    float: right;
    a {
      //color: #000;
      width: 100px;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      img {
        width: 25px;
        height: 25px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
      .anticon {
        margin-left: 10px !important;
      }
    }
  }
}
