.Bread {
  width: 100%;
  height: 40px;
  background: #edf0f5;
  padding-left: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  :global {
    .ant-tag {
      line-height: 30px;
      border: 1px solid #e9eaec !important;
      background: #fff !important;
      padding: 0 12px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .ant-tabs-nav {
      margin-bottom: 0px !important;
    }
    .ant-tabs-tab-remove {
      margin-left: 4px;
    }
    .ant-tabs-tab {
      padding: 2px 12px !important;
    }
  }
  .icon {
    width: 12px;
    height: 12px;
    background: #e9eaec;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 6px;
  }
  .iconActive {
    background: #2d8cf0 !important;
    width: 12px;
    height: 12px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 6px;
  }
  .fontActive {
    color: #2d8cf0 !important;
    font-weight: bold;
  }
  .Tab {
    width: calc(100% - 100px);
    float: left;
  }
  .Bottom {
    width: 100px;
    float: right;
  }
  .BrandTab{
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
}



